<template>
  <div>
    <h3 ref="ia">
      What is a resistor?
    </h3>
    <p>A resistor is an ohmic device that resists the flow of charge (i.e. current) through an electric circuit. Since a resistor is an ohmic device, the voltage drop across a resistor is linearly related to the current flowing through it. $$ V = I R $$ where \(V\) is the voltage across the resistor, \(I \) is the resulting flow of current through the resistor, and \(R\) is the electric resistance of the resistor.</p>
    <h3 ref="ca">
      Resistors in Parallel
    </h3>
    <p>Two resistors are said to be connected in parallel when one ends of the resistors are all together tied to one end of voltage source and the other ends are all tied together to the other end of the voltage source. Thus, each resistor experiences same voltage drop across it. For such a circuit:</p>
    <ul style="list-style-type: square;">
      <li>
        <h3>Equivalent Resistance</h3>
        The equivalent resistance of the circuit is given as: $$\frac{1}{R_{eq}} = \frac{1}{R_1} + \frac{1}{R_2}+ ... + \frac{1}{R_n}$$
      </li>
      <li>
        <h3>Total Current</h3>
        The total current flowing through the circuit is given as: $$I = \frac{V}{R_{eq}} = \frac{V}{R_1} + \frac{V}{R_2}+ ... + \frac{V}{R_n}$$
      </li>
      <li>
        <h3>Voltage Drop</h3>
        The voltage drop across a given resistor (say \(R_i\)) in the circuit is given as: $$V_i = I_i R_i = V $$
      </li>
    </ul>
    <v-layout justify-center="">
      <v-img contain=""
             src="assets/series.png"
             max-height="350px"
             max-width="350px"
             width="400px"
      />
    </v-layout>
    <h3 ref="iat">
      Resistors Connected in Series
    </h3>
    <p>The resistors are said to be connected in series when the current flows through the resistors sequentially. Consider an electric circuit comprising of \(n\) resistors \(R_1\), \(R_2\), ..., \(R_n\) connected in series. For such a circuit:</p>
    <ul style="list-style-type: square;">
      <li>
        <h3>Equivalent Resistance</h3>
        The equivalent resistance of the circuit is given as: $$ R_{eq} = R_1 + R_2 + ... + R_n$$
      </li>
      <li>
        <h3>Net Current</h3>
        The current flowing through the circuit is given as: $$I = \frac{V}{R_{eq}} = \frac{V}{R_1 + R_2 + ... + R_n}$$
      </li>
      <li>
        <h3>Voltage Drop</h3>
        The voltage drop across a given resistor (say \(R_i\)) in the circuit is given as: $$V_i = I R_i = V \frac{R_i}{R_{eq}}$$
      </li>
    </ul>
    <v-layout justify-center="">
      <v-img contain=""
             src="assets/parallel.png"
             max-height="400px"
             max-width="400px"
             width="400px"
      />
    </v-layout>
    <h3 ref="playgraph">
      MagicGraph - Equivalent Resistance Calculator
    </h3>
    <p>This MagicGraph offers students a unique artificially intelligent-visually interactive assistant using which students can:</p>
    <ul style="list-style-type: square;">
      <li>configure an electric circuit of any complexity (series, parallel, or mixed).</li>
      <li>find the equivalent resistance of the circuit.</li>
      <li>determine the current circulating in the circuit.</li>
      <li>calculate the voltage drop across each resistor.</li>
    </ul>
    <v-responsive>
      <v-layout justify-center>
        <div id="jxgbox1" class="edliy-box-about" />
      </v-layout>
    </v-responsive>
  </div>
</template>
<script>
import Boxes from './Boxes.js'
export default {
  name: 'Resistors',
  created: function () {
    this.$store.commit('navigation/resetState');
    let title = 'Resistors in an Electric Circuit';
    this.$store.commit('navigation/changeTitle', title);
    this.$store.commit('navigation/changeMenu', title);
    let newTopics = [
      {title: 'Resistor', img:'assets/number-1.svg', action: () => this.goto('ia')},
      {title: 'Resistors Connected in Parallel', img:'assets/number-2.svg', action: () => this.goto('ca')},
      {title: 'Resistors Connected in Series', img:'assets/number-3.svg', action: () => this.goto('iat')},
      {title: 'Graphical Interpretation',img:'assets/touch.svg', action: () => this.goto('graphical')},
    ];
    this.$store.commit('navigation/replaceTopics', newTopics);
    let newshowhome = false;
    this.$store.commit('navigation/toggleshowhome', newshowhome);
    let newPhys =true;
    this.$store.commit('navigation/replacePhys', newPhys);
    let newLeftArrow =true;
    this.$store.commit('navigation/replaceLeftArrow', newLeftArrow);
    let newModule=true;
    this.$store.commit('navigation/replaceModule', newModule);
  },
  mounted () {
    MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
    Boxes.box1();
  },
}
</script>
<style lang="scss">
@import 'src/styles/subtopic-menu.scss';
@import 'src/styles/edliy-box-about.scss';
@import 'src/styles/screen-sizes.scss';
</style>
