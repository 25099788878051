  import {
      makeResponsive,
      placeTitle,
      placeImage,
      placeInput,
      placeSlider,
      hoverMe,
      placeRec,
      hiddenPt,
      fixedPt,
      clearInputFields,
      dragMe,
      placeArrow,
      placeGravity,
      placeText,
      placeLine,
      placePoint,
      placeGlider,
      placeRuler,
      placeLeftText,
      placeCircle,
      placeAngle,
      placeDash,
      placeLabel,
      placeLogo
  } from '../../../../common/edliy_utils';
  const Boxes = {
  box1: function () {
			      JXG.Options.board.minimizeReflow = 'none';
            JXG.Options.text.highlight =false;
            JXG.Options.text.fixed =true;
            JXG.Options.text.highlight =false;
            JXG.Options.text.cssStyle='fontFamily:Oswald',
            JXG.Options.image.highlight =false;
            JXG.Options.polygon.highlight =false;
            JXG.Options.line.highlight =false;
            JXG.Options.slider.highlight =false;
            var brd2 = JXG.JSXGraph.initBoard('jxgbox1',{boundingbox: [-7, 8, 9, -8],keepaspectratio: true, axis:false, ticks:false, grid:true, pan:{enabled:false}, zoom:{enabled:false},  showCopyright:false, showNavigation:false});
            brd2.options.layer['line'] =2;
            brd2.options.layer['polygon'] =2;
            brd2.options.layer['image'] =8;
            placeLogo(brd2);
            makeResponsive(brd2);
            placeTitle(brd2, 'Resistors in a Circuit', 'Drag the resistors to complete the circuit');
            var a = brd2.create('slider',[[7,-4],[7,4],[0, 1, 2]],{unitLabel:'V',baseline:{strokeWidth:7, strokeColor:'grey'},highline:{strokeWidth:7, strokeColor:'black'}, name:'',size:8,face:'square', fillColor:'grey',strokeColor:'black', withTicks:false, label:{fontSize:function(){return Math.round(16*brd2.canvasWidth/500.)}, cssStyle:'fontFamily:Oswald;'}});
            var li1 = brd2.create('segment', [[0, -4], [0, 4]], {visible:false,strokeWidth:4, strokeColor:'orange', fixed:true});
            var li2 = brd2.create('segment', [[-2, -4], [-2, 4]], {visible:false,strokeWidth:4, strokeColor:'orange', fixed:true});
            var li3 = brd2.create('segment', [[-4, -4], [-4, 4]], {visible:false,strokeWidth:4, strokeColor:'orange', fixed:true});
            var li4 = brd2.create('segment', [[-6, -4], [-6, 4]], {visible:false,strokeWidth:4, strokeColor:'orange', fixed:true});
            brd2.create('segment', [[1, 4], [5, 4]], {visible:true, fixed:true, strokeWidth:4, strokeColor:'orange'});
            brd2.create('segment', [[5, -4], [5, 4]], {visible:true, fixed:true,strokeWidth:4, strokeColor:'orange'});
            brd2.create('segment', [[1, -4], [5, -4]], {visible:true, fixed:true, strokeWidth:4, strokeColor:'orange'});
            brd2.create('image', ['/assets/ui.svg', [4,-1],[2,2]],{fixed:true});
            var res1 = brd2.create('image', ['/assets/resistor.svg', [-4.75,-6],[1.5,1.5]],{attractors:[li1, li2, li3, li4], attractorDistance:1.25, snatchDistance:0.75});
            var res2 = brd2.create('image', ['/assets/resistor.svg', [-1.75,-6],[1.5,1.5]],{attractors:[li1, li2, li3, li4], attractorDistance:1.25, snatchDistance:0.75});
            var res3 = brd2.create('image', ['/assets/resistor.svg', [1.25,-6],[1.5,1.5]],{attractors:[li1, li2, li3, li4],  attractorDistance:1.25,  snatchDistance:0.75});
            var res4 = brd2.create('image', ['/assets/resistor.svg', [4.25,-6],[1.5,1.5]],{attractors:[li1, li2, li3, li4],  attractorDistance:1.25,  snatchDistance:0.75});
            brd2.create('polygon', [[0.75, -4], [0.75, 4], [5, 4],[5, -4]], {fixed:true, vertices:{visible:false, fixed:true}, borders:{strokeColor:'red', strokeWidth:4}, fillOpacity:0, visible:function(){if(res1.X()==0 || res2.X()==0 || res3.X()==0 || res4.X()==0){return true}},strokeWidth:4, strokeColor:'orange'});
            brd2.create('polygon', [[-1.25, -4], [-1.25, 4], [5, 4],[5, -4]], {fixed:true, vertices:{visible:false, fixed:true}, borders:{strokeColor:'red', strokeWidth:4}, fillOpacity:0,visible:function(){if(res1.X()==-2 || res2.X()==-2 || res3.X()==-2 || res4.X()==-2){return true}},strokeWidth:4, strokeColor:'orange'});
            brd2.create('polygon', [[-3.25, -4], [-3.25, 4], [5, 4],[5, -4]], {fixed:true, vertices:{visible:false, fixed:true}, borders:{strokeColor:'red', strokeWidth:4}, fillOpacity:0,visible:function(){if(res1.X()==-4 || res2.X()==-4 || res3.X()==-4 || res4.X()==-4){return true}},strokeWidth:4, strokeColor:'orange'});
            brd2.create('polygon', [[-5.25, -4], [-5.25, 4], [5, 4],[5, -4]], {fixed:true, vertices:{visible:false, fixed:true}, borders:{strokeColor:'red', strokeWidth:4}, fillOpacity:0,visible:function(){if(res1.X()==-6 || res2.X()==-6 || res3.X()==-6 || res4.X()==-6){return true}},strokeWidth:4, strokeColor:'orange'});
            //var a =brd2.create('slider',[[7,4],[7,-4],[0, 1, 2]],{name:'Volts', face:'square'});
            var k =0;
            var km = function(){k=100000000;if(res1.X()==0 || res2.X()==0 || res3.X()==0 || res4.X()==0){k = 0;};if (res1.X()==0){k += 1;}; if (res2.X()==0){k += 1;}; if (res3.X()==0){k += 1}; if (res4.X()==0){k+= 1}; return k;};
            var lm = function(){k=100000000;if(res1.X()==-2 || res2.X()==-2 || res3.X()==-2 || res4.X()==-2){k = 0;};if (res1.X()==-2){k += 1;}; if (res2.X()==-2){k += 1;}; if (res3.X()==-2){k += 1}; if (res4.X()==-2){k+= 1}; return k;};
            var rm = function(){k=100000000;if(res1.X()==-4 || res2.X()==-4 || res3.X()==-4 || res4.X()==-4){k = 0;};if (res1.X()==-4){k += 1;}; if (res2.X()==-4){k += 1;}; if (res3.X()==-4){k += 1}; if (res4.X()==-4){k+= 1}; return k;};
            var sm = function(){k=100000000;if(res1.X()==-6 || res2.X()==-6 || res3.X()==-6 || res4.X()==-6){k = 0;};if (res1.X()==-6){k += 1;}; if (res2.X()==-6){k += 1;}; if (res3.X()==-6){k += 1}; if (res4.X()==-6){k+= 1}; return k;};
            //brd2.create('text',[-5.5, -9.25, '<b> Drag the resistors close to the cell to complete the circuit </b>'],{CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(18*brd2.canvasWidth/500.)}, fixed:true});
            brd2.create('text',[-4, -6.5, 'R_1 = 1 &Omega;'],{anchorX:'middle',fontSize:function(){return Math.round(24*brd2.canvasWidth/800.)}, fixed:true});
            brd2.create('text',[-1, -6.5, 'R_2 = 1 &Omega;'],{anchorX:'middle',fontSize:function(){return Math.round(24*brd2.canvasWidth/800.)}, fixed:true});
            brd2.create('text',[ 2, -6.5, 'R_3 = 1 &Omega;'],{anchorX:'middle',fontSize:function(){return Math.round(24*brd2.canvasWidth/800.)}, fixed:true});
            brd2.create('text',[ 5, -6.5, 'R_4 = 1 &Omega;'],{anchorX:'middle',fontSize:function(){return Math.round(24*brd2.canvasWidth/800.)}, fixed:true});
          //
            brd2.create('text',[-3.5, 5, 'I_{T} (A) = '],{CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(24*brd2.canvasWidth/800.)}, fixed:true});
            brd2.create('text',[-2, 5, function(){return (a.Value()/km() + a.Value()/lm() + a.Value()/rm()+ a.Value()/sm()).toFixed(2);}],{visible:true,CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(24*brd2.canvasWidth/800.)}, fixed:true});
            brd2.create('text',[2, 5, 'R_{EQ} (&ohm;) = '],{CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(24*brd2.canvasWidth/800.)}, fixed:true});
            brd2.create('text',[4., 5, function(){return (1/(1./km() + 1./lm() + 1./rm()+ 1./sm())).toFixed(2);}],{visible:function(){if(km()<=4){return true}},CssStyle:'fontFamily:Oswald',  display:'internal',fontSize:function(){return Math.round(24*brd2.canvasWidth/800.)}, fixed:true});
            brd2.create('text',[4., 5, '&infin;'],{visible:function(){if(km()>4){return true}},CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(24*brd2.canvasWidth/800.)}, fixed:true});

    },
}
export default Boxes;